@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
.footer {
    background-color: $secondary_color;
    padding: 30px 0 20px;
    position: relative;
    overflow: hidden;
    z-index: 1;

    .sections {
        justify-content: flex-end;

        @include responsive-down(md) {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }

    .content_bottom {
        display: none;

        @include responsive-down(md) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            width: 100%;

            a {
                justify-content: center;
            }
        }
    }

    .logo {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        // height: 40px;

        img {
            width: auto;
            // height: 40px;
            display: block;
        }
        a {
            display: flex;
            align-items: center;
            .beta {
                img {
                    padding-left: 2px;
                    max-width: auto;
                    height: 25px;
                }
                // padding: 0.950495px 3.80198px;
                // background: #ff7900;
                // border-radius: 0.475248px 3.80198px;
                // p {
                //   font-style: normal;
                //   font-weight: 500;
                //   font-size: 24px;
                //   line-height: 32px;
                //   letter-spacing: 0.02em;
                //   color: #ffffff;
                //   margin-bottom: 0;
                // }
            }
        }

        @include responsive-up(xl) {
            width: auto;
            margin-right: 100px;
        }
    }

    .tagline {
        margin-top: 15px;
        display: block;
        font-size: 14px;
        line-height: 24px;
        color: $secondary_font_color;
        margin-bottom: 0;
    }

    .social {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;

        a {
            margin-top: 36px;
            margin-right: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            position: relative;

            background-color: $tertiary_color;
            border-radius: 50%;
            padding: 7px;

            svg {
                width: 100%;
                height: auto;
                fill: $primary_font_color;
                transition: fill 0.5s ease;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                background-color: $primary_color;

                svg {
                    fill: #fff;
                }
            }
        }

        @include responsive-down(md) {
            display: flex;
            // flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            width: 100%;
            padding: 10px 0;

            a {
                justify-content: center;
            }
        }
    }

    .content {
        margin-top: 36px;
        border-top: 1px solid rgba(255, 255, 255, 0.1);

        @include responsive-down(md) {
            // display: none;
        }

        .copyright {
            margin-top: 16px;

            font-family: Jost;
            font-style: normal;
            font-weight: normal;
            // font-size: 14px;
            font-size: 85% !important;
            line-height: 20px;
            color: $secondary_font_color;

            letter-spacing: 0.02em;
        }
    }

    .title {
        display: block;
        margin-top: 30px;
        font-size: 16px;
        color: $primary_font_color;
        font-family: $primary_font;
        font-weight: 500;
        margin-bottom: 32px;
    }

    .nav {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        a {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 12px;
            color: $secondary_font_color;
            font-family: $primary_font;
            font-weight: 400;
            text-decoration: none;

            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                color: $primary_font_color;
            }

            @include responsive-down(md) {
                margin-bottom: 10px;
            }
        }

        @include responsive-down(md) {
            justify-content: center;
            align-items: center;
            align-content: center;

            margin: 16px 0;
        }
    }

    // h6.title {
    //   @include responsive-down(md) {
    //     display: none;
    //   }
    // }
}
.row {
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .footer {
        // padding: 20px 0 150px;
        padding: 0px 0 100px;

        .logo {
            margin-top: 50px;
        }
        .title {
            margin-top: 50px;
        }
        .social {
            width: auto;
            order: 2;
            a {
                margin-top: 0;
            }
        }
        .content {
            text-align: center !important;
        }
        .copyright {
            margin-top: 0;
            order: 1;
            padding: 10px;
        }
    }
}

@media screen and (max-width: 767px) {
    .footer {
        // padding: 20px 0 150px;
        padding: 0px 0 180px;
    }
}

@media screen and (min-width: 1200px) {
    .social {
        display: inline-flex;
        width: auto;
    }
}
.paddingLeftChange {
    padding-left: 9px;
}

.nftonFooter {
    /* @include responsive-down(md){
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
      } */

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
    }
}

.demoFlex {
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }

    /* @include responsive-down(md) {
        display: flex;
        flex-direction: column;
      } */
}

.nftLogo {
    display: flex;
    justify-content: center;
}
