@import "styles/_colors.scss"; @import "styles/_variables.scss"; @import "styles/_cmixins.scss";
$break_points: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);

.nft_container {
  margin: 0 auto; 
  max-width: inherit;
  // @include responsive-down(sm) {
  //   padding: 0 !important;
  // }

  @include responsive-up(sm) {
    max-width: 90%;
  }

  @include responsive-up(md) {
    max-width: 720px;
  }

  @include responsive-up(lg) {
    max-width: 950px;
  }

  @include responsive-up(xl) {
    max-width: 1150px;
  }
  @include responsive-up(xxl) {
    max-width: 1420px;
  }
}

.container_sm {
  max-width: 632px;

  @include responsive-down(sm) {
    max-width: 520px;
  }
}

.container_md {
  max-width: 960px;
}

.container_lg {
  max-width: 1080px;
}
.container_xl {
  @include responsive-up(xl) {
    max-width: 1250px;
  }
}
